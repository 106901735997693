// fade between screens is a neutral grey
body {
  opacity: 0;
  transition: ease opacity .2s;
}
body.fadein {
  opacity: 1;
}
// base color for the 'background' before <body> loads
html { background-color: #5c6e7e; }
html.no-back { background-color: $white; }
html.no-back { background-color: $darkblue;}

// don't do dark-mode on no-dark views
body.no-dark {
  height: 100%;
  &::before { display: none !important; }
}
@keyframes hover {
  0% { left: 0; top: 0; }
  50% { left: 5px; top: 5px; }
  100% { left: 0; top: 0; }
}
.cloud {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
}
.cloud, .cloud::before, .cloud::after {
  content: '';
  display: block;
  position: absolute;
  z-index: 2;
  background-color: $darkblue;
  height: 140px; width: 140px;
  border-radius: 50%;
  transform: translate(-100%, -100%);
  transition: ease all .3s;
}
.cloud.hover::before {
  animation: hover 2s infinite;
  // transform: translate(-10%, -55%);
  transform: translate(-70%, 10%);
}
.cloud.hover::after {
  // transform: translate(-40%, -25%);
  transform: translate(-30%, 25%);
  animation: hover 2s infinite;
  animation-delay: .2s;
}
.cloud.hover {
  transform: translate(10%, -65%);
  animation: hover 2s infinite;
  animation-delay: .1s;
}

// the body and footer will have pseudo attributes for backgrounds, in case of transitions
body::before, footer::before {
  content: '';
  display: inline-block;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // transform: scale(0,1);
  // transform-origin: center left;
  transition: ease all .2s;
}
body > div, body > section, body > footer, footer > div, footer > a {
  position: relative;
  z-index: 3;
}
body::before {
  position: fixed;
  background-color: #fff;
}
footer::before {
  position: absolute;
}

// dark mode
body.night {
  position: relative;
  z-index: 1;
  color: $lightblue;
  footer::before { background-color: $blue; }
  &::before { background-color: $darkblue; }
  &::before, footer::before {
    // transition: ease transform .2s;
    // transform: scale(1,1);
  }
  // white
  .cloud::before, .cloud::after, .cloud { background-color: $white; }
  a,
  .blog-post .content blockquote p,
  .blog-index .title { color: $white; }
  // lightblue
  .bar .logo svg path, .bar .logo svg polygon, 
  .blog-index .tab-image svg #book { fill: $lightblue; }
  .blog-index .post-card .right { background-color: $lightblue; }
  .home-sweet-home .title, 
  .the-stacks .name,
  .blog-index .blog-title,
  .blog-index .filter-title,
  .blog-index .filter,
  .blog-index .date,
  .blog-index .tags,
  .blog-index .shortcut { color: $lightblue; }
  // blue
  .blog-index .post-card:hover,
  footer .inverse-social a {
    background-color: $altblue;
    transition: ease all .2s;
  }
  footer .inverse-social a:hover { background-color: $lightblue; }
  // darkblue
  // .blog-page .blog-index { background-color: $darkblue; }
  .the-stacks .rando-bg-fill { fill: $darkblue; }
  .blog-page .blog-index .tabby-top path, .contact .tabby-top path { fill: $darkblue; }
  // grey
  .blog-index .filters,
  .blog-post .content figure.highlight > pre,
  .blog-index { background-color: #1a1a1a;}
  .blog-index .tabby-top path { fill: #1a1a1a;}
  // red
  .home-sweet-home .social a { background-color: $red; }
  .contact .title { color: $lightred; }
  // special cases
  .blog-index .filter.active, .blog-index .filter:hover {
    background-color: $lightblue;
    color: $blue;
    border-color: $blue;
  }
  .blog-index .post-card {
    background-color: $darkblue;
    transition: ease all .2s;
    // background-color: #395f78;
    // border: solid 1px $lightblue;
  }
  .contact select:focus, .contact select option, .contact input, .contact select, .contact textarea {
    background-color: $darkblue;
    color: $lightblue;
    &::placeholder { color: $lightblue; }
    &:disabled { color: $blue; }
  }
  .contact input.submit {
    background-color: $white; 
    color: $darkblue;
    transition: ease all .5s;
    &:hover {
      transition-duration: .2s;
      background-color: $lightred;
    }
  }
}
.toggle-dot, .tabby-top path { transition: ease all .2s; }


// hovering before a click
.bar .logo.switch svg {
  // .toggleBackground, .toggle-dot { fill: $lightblue; }
  path, polygon { fill: $lightblue; }
}
.bar .logo.switch svg .toggle-dot, footer .inverse-logo.switch svg .toggle-dot { transform: translateX(11px); }
.night {
  .toggle-dot { transform: translateX(11px); }
  .bar .logo.switch svg {
    // .toggleBackground, .toggle-dot { fill: $red; }
    path, polygon { fill: $red; }
    .toggle-dot { transform: unset; }
  }
  footer .inverse-logo.switch svg {
    .toggle-dot { transform: unset; }
  }
}

// clicked and still hovering
.bar .logo.switch.switched svg {
  .toggleBackground, .toggle-dot { fill: $red; }
  .toggle-dot { transform: unset; }
}
footer .inverse-logo.switched svg {
  .toggle-dot { transform: unset; }
}
.night {
  .bar .logo.switch.switched svg {
    .toggleBackground, .toggle-dot { fill: $lightblue; }
  }
  .bar .logo.switch.switched svg .toggle-dot, footer .inverse-logo.switch.switched svg .toggle-dot { transform: translateX(11px); }
}
