.contact {
  // position: sticky;
  // bottom: 54px;
  margin-bottom: 115px;
  padding-top: 140px;
  position: relative;
  &.contact-contact {
    padding-top: unset;
    .tabby-top, .tab-image { display: none; }
  }
  .tabby-top {
    position: absolute;
    top: -54px;
    left: 50%;
    transform: translateX(-50%);
    path { fill: $white }
  }
  .tab-image {
    position: absolute;
    top: -27px;
    left: 50%;
    transform: translateX(-50%);
  }
  .container-medium {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .left {
    width: 37%;
    max-width: 375px;
    margin-right: auto;
  }
  .title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 40px;
    color: $red;
  }
  .blurb, .blurb p {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.65px;
    line-height: 1.25;
    & p {
      margin-bottom: 13px;
      &:last-of-type {
        margin-bottom: unset;
      }
    }
  }
  .right {
    width: 60%;
    max-width: 590px;
  }
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  input, select, textarea {
    font: 400 18px / 1.2 $font-fam;
    border-radius: 10px;
    border: solid 1px $border-color;
    background-color: #fff0;
    height: 39px;
    margin-bottom: 14px;
  }
  input, textarea {
    // padding: 3px 10px 0;
    padding-left: 10px;
    &::placeholder {
      opacity: 1;
      color: $text;
    }
  }
  textarea {
    padding-top: 10px;
    height: 200px;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance:none;
    // background: linear-gradient(45deg, rgba(255, 255, 255, 0) 40%, $text 40%, $text 50%, rgba(255, 255, 255, 0) 51%), linear-gradient(135deg, rgba(255, 255, 255, 0) 40%, $text 40%, $text 50%, rgba(255, 255, 255, 0) 51%);
    // background-position: calc(100% - 23px) 14px, calc(100% - 16px) 14px;
    // background-size: 8px 8px, 8px 8px;
    // background-repeat: no-repeat;
    padding-left: 7px;
    &:focus, option {
      background-color: $white;
      color: $blue;
    }
    option:disabled {
      color: $border-color;
    }
  }
  input.submit {
    width: 90px;
    padding-left: unset;
    background-color: $red;
    color: $white;
    border: unset;
    margin-left: auto;
    cursor: pointer;
  }  
  .half { width: calc(50% - 12px); }
  .full { width: 100%; }
}

@media (max-width: 951px) {
  .contact {
    .blurb, .blurb p {
      font-size: 16px;
    }
  }
}

@media (max-width: 905px) {
  .contact {
    .container-medium {
      flex-direction: column;
      width: 80%;
      .left, .right {
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
      .left {
        margin-bottom: 30px;
      }
    }
  }
}

@media (min-width: 951px) {
  .contact-page {
    padding-top: 30px;
  }
  .contact {
    min-height: calc(100vh - 620px);
  }
}
@media (max-width: 651px) {
  .contact .container-medium { width: 90%; }
}

@media (max-width: 490px) {
  .contact {
    .half {
      width: 100%;
    }  
  }
}