/* not to be confused with PostCSS! */

.blog-post {
  margin-bottom: 100px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: auto;
  width: 95%;
  max-width: calc(50% + 625px);
  .article {
    width: 90%;
    max-width: 600px;
    z-index: 2;
  }
  .blog-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 19px;
  }
  .datetime {
    font-weight: 500;
    font-size: 22px;
    color: $blue;
    margin-right: auto;
  }
  .cat {
    text-decoration: none;
    border-radius: 10px;
    border: solid 1px $border-color;
    height: 43px;
    padding: 9px 15px 0;
    color: $blue;
    margin-left: 15px;
    &:hover {
      border-color: $blue;
      background-color: $blue;
      color: $white;
      transition-duration: .2s;
    }
  }
  .title {
    font-size: 37px;
    font-weight: 700;
    color: $red;
    line-height: 1;
    margin-bottom: 32px;
  }
  .content {
    // for ye olde super huge screens
    // overflow: hidden;
    p, ul, li, blockquote, dl, .highlight {
      + h2, + h3, + h4 {
        margin-top: 41px;
      }
    }
    h2, h3, h4, h5 {
      color: $blue;
    }
    h2 {
      margin-bottom: 27px;
      font-size: 32px;
      color: $blue;
      font-weight: 700;
    }
    h3 {
      margin-bottom: 21px;
      font-weight: 600;
      font-size: 30px;
    }
    h4 {
      color: $blue;
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 16px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 21px;
    }
    blockquote {
      display: inline-block;
      margin-bottom: 21px;
      border: solid 1px $border-color;
      border-radius: 10px;
      padding: 15px 22px;
      p {
        font-weight: 500;
        width: unset;
        color: $blue;
        &:last-of-type { margin-bottom: unset; }
      }
    }
    strong, dt { font-weight: 600; }
    dt {
      margin-bottom: 16px;
    }
    dd {
      margin-bottom: 11px;
      padding-left: 20px;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 8px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: $blue;
      }
    }
    ol, ul {
      padding: 0 20px 28px;
      margin-top: -10px;
      li {
        margin-bottom: 7px;
      }
    }
    ol li {
      // list-style: unset inside;
    }
    ul li {
      list-style: none inside;
      &:before {
        content: '';
        display: inline-block;
        height: 0;
        border: solid transparent 5px;
        border-left: solid $red 8px;
        padding-right: 5px;
        border-radius: 50%;
      }
    }
    ol ol, ul ul, ol ul, ul ol {
      margin-top: 5px !important;
      padding-bottom: 0 !important;
    }
    hr {
      border: none;
      border-top-color: currentcolor;
      border-top-style: none;
      border-top-width: medium;
      border-top: dashed $red;
      margin: 35px 35px;
    }
    .highlight, pre, code {
      font-family: $mono-font;
    }
    figure.highlight {
      // width: 90vw;
      width: calc(50vw + 617px) ;
      max-width: calc(90vw);
      > pre {
        overflow: auto;
        background-color: #eeea;
        padding: 25px 35px;
        margin-bottom: 21px;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }

    .highlighter-rouge {
      font-family: $mono-font;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.4px;
      color: $green;
    }

    .highlight .hll { background-color: #ffffcc }
    .highlight .c { color: #aaaaaa; font-style: italic } /* Comment */
    .highlight .err { color: #F00000; background-color: #F0A0A0 } /* Error */
    .highlight .k { color: $altblue } /* Keyword */
    .highlight .cm { color: #aaaaaa; font-style: italic } /* Comment.Multiline */
    .highlight .cp { color: #4c8317 } /* Comment.Preproc */
    .highlight .c1 { color: #aaaaaa; font-style: italic } /* Comment.Single */
    .highlight .cs { color: $altblue; font-style: italic } /* Comment.Special */
    .highlight .gd { color: $red } /* Generic.Deleted */
    .highlight .ge { font-style: italic } /* Generic.Emph */
    .highlight .gr { color: $red } /* Generic.Error */
    .highlight .gh { color: $blue; font-weight: bold } /* Generic.Heading */
    .highlight .gi { color: #3bac1b } /* Generic.Inserted */
    .highlight .go { color: #888888 } /* Generic.Output */
    .highlight .gp { color: #555555 } /* Generic.Prompt */
    .highlight .gs { font-weight: bold } /* Generic.Strong */
    .highlight .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
    .highlight .gt { color: $red } /* Generic.Traceback */
    .highlight .kc { color: $altblue } /* Keyword.Constant */
    .highlight .kd { color: $altblue } /* Keyword.Declaration */
    .highlight .kn { color: $altblue } /* Keyword.Namespace */
    .highlight .kp { color: $altblue } /* Keyword.Pseudo */
    .highlight .kr { color: $altblue } /* Keyword.Reserved */
    .highlight .kt { color: #4ca292 } /* Keyword.Type */
    .highlight .m { color: $green; } /* Literal.Number */
    .highlight .s { color: $orange } /* Literal.String */
    .highlight .na { color: #1e90ff } /* Name.Attribute */
    .highlight .nb { color: #4ca292 } /* Name.Builtin */
    .highlight .nc { color: #3bac1b; text-decoration: underline } /* Name.Class */
    .highlight .no { color: $blue } /* Name.Constant */
    .highlight .nd { color: #888888 } /* Name.Decorator */
    .highlight .ni { color: #800000; font-weight: bold } /* Name.Entity */
    .highlight .nf { color: #3bac1b } /* Name.Function */
    .highlight .nn { color: #4ca292; text-decoration: underline } /* Name.Namespace */
    .highlight .nt { color: #1e90ff; font-weight: bold } /* Name.Tag */
    .highlight .nv { color: $blue } /* Name.Variable */
    .highlight .ow { color: $altblue } /* Operator.Word */
    .highlight .w { color: #bbbbbb } /* Text.Whitespace */
    .highlight .mf { color: $green; } /* Literal.Number.Float */
    .highlight .mh { color: $green; } /* Literal.Number.Hex */
    .highlight .mi { color: $green; } /* Literal.Number.Integer */
    .highlight .mo { color: $green; } /* Literal.Number.Oct */
    .highlight .sb { color: $orange } /* Literal.String.Backtick */
    .highlight .sc { color: $orange } /* Literal.String.Char */
    .highlight .sd { color: $orange } /* Literal.String.Doc */
    .highlight .s2 { color: $orange } /* Literal.String.Double */
    .highlight .se { color: $orange } /* Literal.String.Escape */
    .highlight .sh { color: $orange } /* Literal.String.Heredoc */
    .highlight .si { color: $orange } /* Literal.String.Interpol */
    .highlight .sx { color: $orange } /* Literal.String.Other */
    .highlight .sr { color: $green; } /* Literal.String.Regex */
    .highlight .s1 { color: $orange } /* Literal.String.Single */
    .highlight .ss { color: $altblue } /* Literal.String.Symbol */
    .highlight .bp { color: #4ca292 } /* Name.Builtin.Pseudo */
    .highlight .vc { color: $red } /* Name.Variable.Class */
    .highlight .vg { color: $red } /* Name.Variable.Global */
    .highlight .vi { color: $red } /* Name.Variable.Instance */
    .highlight .il { color: $green; } /* Literal.Number.Integer.Long */
  }
  .outer-hero {
    clip-path: inset(0 0);
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100vh - 97px);
    width: calc(100% - 640px);
    margin-left: auto;
    // max-width: 390px;
  }
  .hero {
    // max-height: 675px;
    height: calc(100vh - 97px);
    width: calc(100% - 640px);
    position: fixed;
    top: 97px;
    right: 0;
    background: center / cover no-repeat;
    // &::after {
    //   content: '';
    //   display: inline-block;
    //   position: relative;
    //   padding-top: 175%;
    // }
  }
}
