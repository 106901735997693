@keyframes orbit { 100% { transform:rotate(-360deg); } }


.home-sweet-home {
  margin-top: 100px;
  margin-bottom: 195px;
  .container-narrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  .left {
    width: 47%;
    position: relative;
    .top-left {
      animation: orbit 6s linear infinite;
      transform-origin: 53% center;
      top: 15%;
      left: -6%;
      width: 28%;
      &::after {
        padding-top: 95%;
        background-color: $blue;
        animation: orbit 6s linear reverse infinite;
        border-radius: 70% 30% 30% 70% / 40% 60% 40% 60%;
      }
    }
    .top-right {
      animation: orbit 5s linear reverse infinite;
      transform-origin: 54% center;
      top: 15%;
      right: 7%;
      width: 17%;
      &::after {
        padding-top: 85%;
        background-color: $red;
        animation: orbit 5s linear infinite;
        border-radius: 54% 46% 70% 30% / 56% 30% 70% 44%;
      }
    }
    .bottom {
      animation: orbit 7s linear infinite;
      transform-origin: 52% center;
      bottom: -5%;
      left: 3%;
      width: 40%;
      &::after {
        padding-top: 95%;
        background-color: $red;
        animation: orbit 7s linear reverse infinite;
        border-radius: 70% 30% 30% 70% / 40% 60% 40% 60%;
      }
    }
    .bubble {
      position: absolute;
      &::after {
        content: '';
        display: block;
        position: relative;
      }
    }
    img { width: 100%; }
  }
  .right {
    margin-bottom: 22px;
    width: 40%;
  }
  .title {
    margin-bottom: 14px;
    font-size: 40px;
    color: $red;
  }
  .text {
    margin-bottom: 27px;
    width: 100%;
    max-width: 375px; // really unnecessary because math
    font-size: 18px;

    .button {
      width: 90px;
      padding: 5px 10px 7px;
      border-radius: 15px;
      text-decoration: none;
      background-color: $red;
      color: $white;
      border: unset;
      margin-left: auto;
      cursor: pointer;
      transition: .5s;
      &:hover {
        background-color: $white;
        outline: solid 1px $blue;
        color: $red;
        transition: .2s;
      }
    }
  }
  .social {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    a {
      height: 43px;
      width: 43px;
      margin-right: 14px;
      text-decoration: none;
      border-radius: 50%;
      background-color: $blue;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      &:last-of-type {
        margin-right: unset;
      }
      img {
        width: 60%;
        height: auto;
      }
    }
  }
}
.the-stacks {
  margin-bottom: 154px;
  .container-wide {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    border: solid 1px $border-color;
    border-radius: 35px;
  }
  .role {
    width: 25%;
    padding: 30px 0 80px;
    border-right: solid 1px $border-color;
    margin-right: -1px;
    &:last-of-type {
      border-right: unset;
      margin-right: unset;
    }
  }
  .illy {
    display: block;
    width: 55%;
    object-fit: contain;
    object-position: top center;
    margin: 0 auto 10px;
    &::after {
      content: '';
      display: inline-block;
      position: relative;
      padding-top: 100%
    }
  }
  .rando-bg-fill { fill: $white; }
  .name {
    text-align: center;
    font-size: 29px;
    color: $red;
    margin-bottom: 30px;
  }
  .blurb {
    width: 80%;
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
  }
}
.testimonials {
  margin-bottom: 184px;
  .container-narrow {
    :focus { box-shadow: unset !important; }
    position: relative;
    width: 100%;
    display: block;
    padding-bottom: 150px;
  }
  .slider {
    padding: 50px 5%;
    max-width: calc(90% - 170px);
    .slick-track {
      display: flex !important;
      align-items: stretch;
      .slide {
        height: inherit !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .upper, .lower {
    display: flex !important;
    flex-wrap: wrap;
  }
  .upper {
    height: calc(100%);
    justify-content: center;
    align-items: center;
  }
  .open-quote-block,
  .close-quote-block {
    width: 85px;
    height: 85px;
    font-size: 100px;
    font-weight: 900;
    font-family: $serif-font;
    color: $red;
    text-align: center;
    line-height: 1.1;
    display: block;
  }
  .open-quote-block { margin-bottom: auto; }
  .close-quote-block { margin-top: auto; }
  .quote {
    font-size: 34px;
    font-weight: 600;
    line-height: 1.1;
    span, a {
      color: $blue;
      font-weight: 900;
    }
  }
  .lower {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 150px;
    align-items: center;
    justify-content: center;
    .slick-track {
      overflow: hidden;
    }
    .img-slider {
      width: 150px;
      &:nth-child(3n-2) img {
        background-color: $red;
        color: $white;
        border-radius: 21% 79% 70% 30% / 56% 76% 24% 44%;
      }
      // &:nth-child(3n-1) .quote-block,
      &:nth-child(3n-1) img {
        background-color: $lightblue;
        color: $red;
        border-radius: 63% 37% 70% 30% / 28% 20% 80% 72%;
      }
      // &:nth-child(3n) .quote-block,
      &:nth-child(3n) img {
        background-color: $blue;
        color: $lightblue;
        border-radius: 81% 19% 28% 72% / 61% 48% 52% 39%;
      }
      img {
        width: 150px;
        aspect-ratio: 1/1;
        object-fit: cover;
        filter: grayscale(100%);
      }
    }
    .author-slider {
      max-width: calc(80% - 150px);
      margin-left: 5%;
      .author {
        font-weight: 600;
        font-family: $serif-font;
        em {
          font-weight: 400;
          font-family: $font-fam;
        }
        br { display: none; }
      }
    }
  }
}



@media (max-width: 951px) {
  .home-sweet-home {
    margin-bottom: 100px;
    margin-top: 80px;
    .container-narrow {
      align-items: center;
    }
    .right {
      width: 47%;
    }
  }
  .the-stacks {
    .container-wide {
      width: 80%;
    }
    .role {
      width: 50%;
      padding: 0px 0 25px;
      &:nth-child(1), &:nth-child(2) {
        border-bottom: solid $border-color 1px;
      }
      &:nth-child(2n) {
        margin-right: unset;
        border-right: unset;
      }
    }
    .illy {
      margin-bottom: 0;
      margin-top: 10px;
      height: unset;
      aspect-ratio: 1;
    }
  }
  .testimonials {
    .quote {
      font-size: 28px;
    }
  }
}

@media (max-width: 651px) {
  .home-sweet-home {
    margin-top: 20px;
    .container-narrow {
      flex-direction: column;
    }
    .left, .right {
      width: 100%;
      max-width: 375px;
      margin: 0 auto;
    }
    .left {
      margin-bottom: 30px;
      .bottom {
        bottom: calc(30px - 5%);
      }
    }
  }
  .the-stacks {
    .container-wide {
      width: 90%;
    }
  }
  .testimonials {
    .container-narrow { padding-bottom: 170px; }
    .open-quote-block, .close-quote-block {
      width: 55px;
    }
    .slider {
      max-width: calc(90% - 110px);
      .quote {
        font-size: 20px;
      }
    }
    .lower {
      width: 90%;
      // left: 0;
      // transform: unset;
      .img-slider { width: 100px; }
      .author-slider { max-width: calc(90% - 100px);}
    }
  }
}

@media (max-width: 501px) {
  .testimonials {
    .open-quote-block, .close-quote-block {
      width: 35px;
      height: 35px; 
      font-size: 40px;
    }
    .slider {
      max-width: calc(90% - 110px);
      padding: 20px 5%;
      .quote {
        font-size: 18px;
      }
    }

    .lower .author-slider .author {
      span {
        width: 0;
        overflow: hidden;
        display: block;
        height: 8px;
      }
      br { display: block; }
    }
  }
}
