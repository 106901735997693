/* color variables */
$red: #d42c41;
$lightred: #f6abbd;
// $yellow: #dfca46;
$orange: #b55332;
$green: #58cb58;
$blue: #4c80a2;
$altblue: #1c5479;
$lightblue: #c8def0;
$darkblue: #0f293a;
$border-color: #cddeec;
$grey: #c2b5b5;
$text: #363636;
$white: #fff;
$bg-color: $white;

// text variables
$font-fam: freight-sans-pro, sans-serif;
$serif-font: freight-text-pro, Helvetica, serif;
// $font-fam: $serif-font;
$mono-font: calling-code, mono;

/* general */
* > * {
  margin: 0;
  padding: 0;
}
*:focus {
  outline: none;
  box-shadow: 0 0 0 2px $red;
}
*, *::before, *::after { box-sizing: border-box; }
html {
  height: 100%;
  scroll-snap-type: y proximity;
}
// .bar, 
// section, 
// footer,
.snap {
  // scroll-snap-align: start;
  scroll-margin-top: 60px;
}
.post-card {  scroll-margin-top: 100px; }
// h3,
// h2, 
h1 { 
  // scroll-snap-align: start;
  scroll-margin-top: 30px;
}
html, body {
  margin: 0;
  // height: 100%;
  font: 400 18px/1.3 $font-fam;
  color: $text;
  // background: $white;
  &.locked {
    max-height: 100%;
    overflow: hidden;
  }
}
::selection {
  background-color: $blue;
  color: $white;
}

h1, h2, h3, h4, h5 {
  font: 700 40px/1.3 $font-fam;
}
p {
  font-family: $font-fam;
}
a {
  text-decoration: none;
  font-weight: 900;
  color: $red;
  &:hover { text-decoration: underline; }
}
li {
  list-style: none inside;
}

picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.container-wide {
  width: 90%;
  max-width: 1250px;
  margin: 0 auto;
}
.container-medium {
  width: 90%;
  max-width: 1150px;
  margin: 0 auto;
}
.container-narrow {
  width: 85%;
  max-width: 950px;
  margin: 0 auto;
}
.bar {
  width: 90%;
  max-width: 1250px;
  margin: 0 auto;
  padding-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  .logo {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100px;
    svg {
      width: 90%;
      height: auto;
      z-index: 5;
      path, polygon {
        fill: $red;
      }
      .toggle-dot { transition: ease all .15s; }
    }
  }
  nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    .link {
      display: block;
      padding: 10px;
      font-weight: 500;
      &.active {
        // color: $blue;
        font-weight: 600;
      }
    }
  }
}

// Animations
@keyframes throb {
  0% { opacity: 1; }
  50% { opacity: 0 }
  51% { opacity: 1; }
  100% { opacity: 0 }
}

@media (max-width: 800px) {
  html, body {
    font: 300 16px/1.2 $font-fam;
  }
}

@media (max-width: 401px) {
  nav .link:first-of-type { padding-left: unset; }
}