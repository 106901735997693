
// Resume/PDFviewer
.pdfview {
  height: 99.5%;
  width: 100%;
}
// that pop-up on the resume page
.popup {
  position: fixed;
  top: 15px;
  left: 80%;
  transform: translateX(-80%);
  padding: 20px;
  color: $red;
  text-align: center;
  background-color: white;
  border-top-left-radius:     15px;
  border-bottom-left-radius:  15px;
  border-bottom-right-radius: 15px;
  border: solid 1px $border-color;
  max-height: 0;
  min-width: 130px;
  opacity: 0;
  overflow: hidden;
  transition: ease all .5s;
  &.shown {
    max-height: 100px;
    opacity: 1;
    span {
      &:first-of-type {
        transform: rotate(-45deg);
      }
      &:last-of-type {
        transform: rotate(45deg);
      }
    }
  }
  .exit {
    position: absolute;
    top: 5px; right: 5px;
    height: 20px;
    width: 20px;
    span {
      position: absolute;
      height: 2px;
      width: 20px;
      right: 0;
      top: 10px;
      background-color: $red; 
      transition: ease all .5s;
    }
  }
}

